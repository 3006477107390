<template>
    <div class="error-box">
        <img src="@/assets/images/404.png">
    </div>
</template>
<script>
export default {
    name: "error404"
}
</script>
<style lang="less" scoped>

</style>
